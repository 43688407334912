body {
  /* background-color: cadetblue; */
  background-image: url('./assets/bgimg.jpg');
  
}


.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 11px;  
}

.card {
  width: 100px;
  height: 65px;
  display: flex;
  border-radius: 8px;
  object-fit: contain;
  overflow: hidden;
  /* aspect-ratio: calc(1/2); */
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.card-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.fullscreen-image img {
  max-width: 100%;
  max-height: 100%;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}
/* set zoomout duration here >>*/
.zoom-out {
  animation: zoomOut 12s forwards;
}
